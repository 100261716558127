/* NAVIGATION */
export const NAVIGATE_TO_CREATE_FEATURE = 'NAVIGATE_TO_CREATE_FEATURE';
export const CREATE_API_TOKEN_BUTTON = 'CREATE_API_TOKEN_BUTTON';

/* CREATE FEATURE */
export const CF_NAME_ID = 'CF_NAME_ID';
export const CF_TYPE_ID = 'CF_TYPE_ID';
export const CF_DESC_ID = 'CF_DESC_ID';
export const CF_CREATE_BTN_ID = 'CF_CREATE_BTN_ID';

/* LOGIN */
export const LOGIN_EMAIL_ID = 'LOGIN_EMAIL_ID';
export const LOGIN_BUTTON = 'LOGIN_BUTTON';
export const LOGIN_PASSWORD_ID = 'LOGIN_PASSWORD_ID';
export const SSO_LOGIN_BUTTON = 'SSO_LOGIN_BUTTON';
export const FORGOTTEN_PASSWORD_FIELD = 'FORGOTTEN_PASSWORD_FIELD';
export const INVALID_TOKEN_BUTTON = 'INVALID_TOKEN_BUTTON';

/* STRATEGY */
export const FEATURE_ENVIRONMENT_ACCORDION = 'FEATURE_ENVIRONMENT_ACCORDION';
export const ADD_NEW_STRATEGY_ID = 'ADD_NEW_STRATEGY_ID';
export const ROLLOUT_SLIDER_ID = 'ROLLOUT_SLIDER_ID';
export const DIALOGUE_CONFIRM_ID = 'DIALOGUE_CONFIRM_ID';
export const CONSTRAINT_AUTOCOMPLETE_ID = 'CONSTRAINT_AUTOCOMPLETE_ID';
export const FLEXIBLE_STRATEGY_STICKINESS_ID =
    'FLEXIBLE_STRATEGY_STICKINESS_ID';
export const FLEXIBLE_STRATEGY_GROUP_ID = 'FLEXIBLE_STRATEGY_GROUP_ID';
export const SELECT_ITEM_ID = 'SELECT_ITEM_ID';
export const STRATEGY_INPUT_LIST = 'STRATEGY_INPUT_LIST';
export const ADD_TO_STRATEGY_INPUT_LIST = 'ADD_TO_STRATEGY_INPUT_LIST';
export const STRATEGY_FORM_SUBMIT_ID = 'STRATEGY_FORM_SUBMIT_ID';
export const STRATEGY_FORM_REMOVE_ID = 'STRATEGY_FORM_REMOVE_ID';

/* SPLASH */
export const CLOSE_SPLASH = 'CLOSE_SPLASH';

/* GENERAL */
export const INPUT_ERROR_TEXT = 'INPUT_ERROR_TEXT';
export const HEADER_USER_AVATAR = 'HEADER_USER_AVATAR';
export const SIDEBAR_MODAL_ID = 'SIDEBAR_MODAL_ID';
